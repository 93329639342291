<template>
  <b-dropdown
    v-if="isVisible"
    :title="$t('fCustomer_actions_title')"
    right
    no-caret
    toggle-class="text-decoration-none"
    variant="link"
    size="lg"
  >
    <template #button-content>
      <font-awesome-icon icon="ellipsis-vertical" />
    </template>
    <component
      :is="option.type"
      v-for="option in options"
      :key="option.label"
      :variant="option.variant || 'secondary'"
      @click.stop.prevent="option.action"
    >
      <font-awesome-icon v-if="option.icon" :icon="option.icon" class="mr-2" />
      {{ option.label }}
    </component>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ActionsDropdown',
  props: {
    delete: {
      type: Boolean,
      default: false
    },
    restore: {
      type: Boolean,
      default: false
    },
    deletePermanent: {
      type: Boolean,
      default: false
    },
    impersonate: {
      type: Boolean,
      default: false
    },
    impersonateUnlock: {
      type: Boolean,
      default: false
    },
    clubMemeber: {
      type: Boolean,
      default: false
    },
    clubMemeberDisable: {
      type: Boolean,
      default: false
    },
    clubMemeberStaffDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible () {
      return this.delete ||
        this.restore ||
        this.deletePermanent ||
        this.impersonate ||
        this.impersonateUnlock ||
        this.clubMemeber ||
        this.clubMemeberDisable
    },
    options () {
      const options = []
      if (this.clubMemeber) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_club_staff_add'),
          action: e => this.$emit('on-club-add', e),
          icon: 'users'
        })
      }
      if (this.clubMemeberStaffDisable) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_club_staff_delete'),
          action: e => this.$emit('on-club-delete', e),
          variant: 'info',
          icon: 'users-slash'
        })
      }
      if (this.clubMemeberDisable) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_club_delete'),
          action: e => this.$emit('on-club-delete', e),
          variant: 'info',
          icon: 'users-slash'
        })
      }
      if (this.impersonate) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_imper_lock'),
          action: e => this.$emit('on-impersonate', e),
          icon: 'user-lock'
        })
      }
      if (this.impersonateUnlock) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_imper_unlock'),
          action: e => this.$emit('on-impersonate-unlock', e),
          variant: 'info',
          icon: 'user-shield'
        })
      }
      if (this.delete) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_Btn_delete'),
          action: e => this.$emit('on-delete', e),
          variant: 'danger',
          icon: 'user-slash'
        })
      }
      if (this.restore) {
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_Btn_restore'),
          action: e => this.$emit('on-restore', e),
          variant: 'info',
          icon: 'user-check'
        })
      }
      if (this.deletePermanent) {
        options.push({
          type: 'b-dropdown-divider'
        })
        options.push({
          type: 'b-dropdown-item',
          label: this.$t('fCustomer_Btn_deletePermanent'),
          action: e => this.$emit('on-delete-permanent', e),
          variant: 'danger',
          icon: 'user-xmark'
        })
      }
      return options
    }
  }
}
</script>

<style scoped>

</style>
