<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-link
          class="text-gray-700 h3 d-block my-0"
          @click="state = !state"
        >
          <font-awesome-icon
            class="mr-1"
            :icon="['fas', state ? 'square-minus' : 'square-plus']"
          />
          {{ label }}
        </b-link>
        <b-collapse v-model="state" class="mt-2">
          <slot />
        </b-collapse>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Your label'
    }
  },
  data () {
    return {
      state: this.value
    }
  },
  watch: {
    value (n) {
      this.state = n
    }
  }
}
</script>

<style scoped>

</style>
