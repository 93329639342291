<template>
  <div v-if="userClubAccessLog && userClubAccessLog.length">
    <b-table
      v-if="!isMobile"
      striped
      hover
      small
      class="datatable table-responsive"
      :items="userClubAccessLog"
      :fields="fields"
    >
      <template #cell(created_at)="data">
        <span v-shtml="data.value" />
      </template>
      <template #cell(staffUser)="{ item }">
        <span v-if="item.staffUser">
          <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
          <span class="">{{ item.staffUser.email }}</span>
        </span>
      </template>
      <template #cell(action)="{ item }">
        <span v-if="item.staffUser">
          <span
            class="font-weight-bold"
            :class="{'text-success': (item.action === 'Enable'), 'text-danger': (item.action === 'Disable')}"
          >{{ item.action }} </span>
        </span>
      </template>
    </b-table>
    <div v-else>
      <timeline :items="userClubAccessLog">
        <template #default="{ data: { item } }">
          <h5 class="fw-bold">
            {{ item.created_at ? $dateFormat(item.created_at, 'DD/MM/YYYY HH:mm') : '' }}
          </h5>
          <p class="text-muted mb-2 fw-bold">
            <span v-if="item.staffUser">
              <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
              <span class="">{{ item.staffUser.email }}</span>
            </span>
            <span v-else>Hostess</span>
          </p>
          <p class="p-0 text-muted">
            <strong>{{ $t('fCustomer_clubAccessLog_f_action') }}</strong>: {{ item.action }}
          </p>
        </template>
      </timeline>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'ClubAccessLog',
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'created_at',
          label: this.$t('fCustomer_clubAccessLog_f_date'),
          sortable: true,
          tdClass: 'col-2 text-left',
          formatter: (value, key, item) => {
            return item?.created_at
              ? this.$dateFormat(item.created_at, 'DD/MM/YYYY<br>HH:mm')
              : ''
          }
        },
        {
          key: 'staffUser',
          label: this.$t('fCustomer_clubAccessLog_f_staff_user'),
          sortable: true,
          formatter: (value) => {
            return value ? `${value.email}&nbsp;(Id:&nbsp;${value.id})` : 'Hostess'
          }
        },
        {
          key: 'action',
          label: this.$t('fCustomer_clubAccessLog_f_action'),
          tdClass: 'col-2 text-left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device'
    }),
    ...mapComputedStateFields('customerEdit', [
      'userClubAccessLog'
    ]),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    }
  },
  watch: {},
  async mounted () {
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style scoped>

</style>
