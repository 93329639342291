<template>
  <b-dropdown
    :disabled="disabled"
    :text="$t('fCustomer_wa_title')"
    class="m-md-2"
    variant="success"
    size="sm"
  >
    <b-dropdown-item @click.stop.prevent="openChat">
      {{ $t('fCustomer_wa_openChat') }}
    </b-dropdown-item>
    <b-dropdown-item @click.stop.prevent="openChatWithMessage">
      {{ $t('fCustomer_wa_openChatWithMessage') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
// import { isServer } from '@/mixins/helpers'

export default {
  name: 'WhatsappDropdown',
  props: {
    url: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: String,
      default: ''
    }
  },
  computed: {
    message () {
      const msg = this.$t('fCustomer_wa_message', { url: this.url })
      return encodeURI(msg)
    },
    phoneNumberFormatted () {
      let num = this.phoneNumber
      num = num.replaceAll(/[+() -]|^00?/ig, '')
      if (num.length === 10) {
        num = `39${num}`
      } else if (num.length < 9) {
        num = ''
      }
      return num
    },
    disabled () {
      return this.phoneNumberFormatted === ''
    }
  },
  methods: {
    openChat (e) {
      if (!isServer) {
        if (confirm('Open Whatsapp at browser?')) {
          window.open(`https://web.whatsapp.com/send/?phone=${this.phoneNumberFormatted}`, '_blank').focus()
        } else {
          window.open(`https://wa.me/${this.phoneNumberFormatted}`, '_blank').focus()
        }
      }
    },
    openChatWithMessage (e) {
      if (!isServer) {
        if (confirm('Open Whatsapp at browser?')) {
          window.open(`https://web.whatsapp.com/send/?phone=${this.phoneNumberFormatted}&text=${this.message}`, '_blank').focus()
        } else {
          window.open(`https://wa.me/${this.phoneNumberFormatted}?text=${this.message}`, '_blank').focus()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
