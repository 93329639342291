var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"border-variant":"danger","header":_vm.$t('fCustomer_duplInfo_title') + " (" + (_vm.items.length) + ")","header-text-variant":"secondary","header-bg-variant":"danger"}},[_c('b-row',[_c('b-col',{staticClass:"overflow-auto",attrs:{"cols":12}},[_c('b-table',{staticClass:"datatable text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.items,"head-variant":"dark","striped":"","hover":"","small":"","bordered":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('user-profile-link',{attrs:{"user":item}})]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.email)+" "),(item.match_email == 1)?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'triangle-exclamation']}}):_vm._e()]}},{key:"cell(mobile)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.mobile)+" "),(item.match_mobile == 1)?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'triangle-exclamation']}}):_vm._e()]}},{key:"cell(telegram)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.telegram)+" "),(item.match_telegram == 1)?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'triangle-exclamation']}}):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 'deleted')?_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v("\n              "+_vm._s(item.status)+"\n            ")]):(item.status === 'inactive')?_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v("\n              "+_vm._s(item.status)+"\n            ")]):(item.status === 'active')?_c('span',{staticClass:"text-success font-weight-bold"},[_vm._v("\n              "+_vm._s(item.status)+"\n            ")]):(item.user_profile)?_c('span',{staticClass:"text-info font-weight-bold"},[_vm._v("\n              "+_vm._s(item.status)+"\n            ")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }