<template>
  <div>
    <b-card
      border-variant="danger"
      :header="$t('fCustomer_duplInfo_title') + ` (${items.length})`"
      header-text-variant="secondary"
      header-bg-variant="danger"
    >
      <b-row>
        <b-col
          :cols="12"
          class="overflow-auto"
        >
          <b-table
            :fields="fields"
            :items="items"
            class="datatable text-nowrap"
            head-variant="dark"
            striped
            hover
            small
            bordered
          >
            <template #cell(name)="{ item }">
              <user-profile-link
                :user="item"
              />
            </template>
            <template #cell(email)="{ item }">
              {{ item.email }} <font-awesome-icon v-if="item.match_email == 1" :icon="['fas', 'triangle-exclamation']" class="text-danger" />
            </template>
            <template #cell(mobile)="{ item }">
              {{ item.mobile }} <font-awesome-icon v-if="item.match_mobile == 1" :icon="['fas', 'triangle-exclamation']" class="text-danger" />
            </template>
            <template #cell(telegram)="{ item }">
              {{ item.telegram }} <font-awesome-icon v-if="item.match_telegram == 1" :icon="['fas', 'triangle-exclamation']" class="text-danger" />
            </template>
            <template #cell(status)="{ item }">
              <span v-if="item.status === 'deleted'" class="text-danger font-weight-bold">
                {{ item.status }}
              </span>
              <span v-else-if="item.status === 'inactive'" class="text-muted font-weight-bold">
                {{ item.status }}
              </span>
              <span v-else-if="item.status === 'active'" class="text-success font-weight-bold">
                {{ item.status }}
              </span>
              <span v-else-if="item.user_profile" class="text-info font-weight-bold">
                {{ item.status }}
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'EmailAndMobileDuplicates',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'user_id',
          label: this.$t('fCustomer_duplInfo_f_userId')
        },
        {
          key: 'name',
          label: this.$t('fCustomer_duplInfo_f_name'),
          formatter: (value, key, item) => {
            return item.user_profile &&
              item.user_profile.display_name
          }
        },
        {
          key: 'mobile',
          label: this.$t('fCustomer_duplInfo_f_mobile')
        },
        {
          key: 'email',
          label: this.$t('fCustomer_duplInfo_f_email')
        },
        {
          key: 'telegram',
          label: this.$t('fCustomer_duplInfo_f_telegram')
        },
        {
          key: 'created_at',
          label: this.$t('fCustomer_duplInfo_f_createdAt'),
          formatter: (value, key, item) => {
            return item?.created_at
              ? this.$dateFormat(item.created_at)
              : ''
          }
        },
        {
          key: 'status',
          label: ''
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
