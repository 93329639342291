<template>
  <div v-if="statusLog && statusLog.length">
    <b-table
      v-if="!isMobile"
      striped
      hover
      small
      class="datatable table-responsive"
      :items="statusLog"
      :fields="fields"
    >
      <template #cell(date)="data">
        <span v-shtml="data.value" />
      </template>
      <template #cell(staffUser)="{ item }">
        <span v-if="item.staffUser">
          <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
          <span class="">{{ item.staffUser.email }}</span>
        </span>
        <span v-else>User</span>
      </template>
      <template #cell(status)="{ item }">
        <b-badge
          :style="`background-color: ${item.color}`"
        >
          {{ item.status }}
        </b-badge>
      </template>
    </b-table>
    <div v-else>
      <timeline :items="statusLog">
        <template #default="{ data: { item } }">
          <h5 class="fw-bold">
            {{ item.date ? $dateFormat(item.date, 'DD/MM/YYYY HH:mm') : '' }}
          </h5>
          <p class="text-muted mb-2 fw-bold">
            <span v-if="item.staffUser">
              <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
              <span class="">{{ item.staffUser.email }}</span>
            </span>
            <span v-else>User</span>
          </p>
          <p class="p-0 text-muted">
            <strong>{{ $t('fCustomer_statusLog_f_status') }}</strong>: {{ item.value }}
          </p>
        </template>
      </timeline>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'StatusLog',
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'date',
          label: this.$t('fCustomer_statusLog_f_date'),
          sortable: true,
          tdClass: 'col-2 text-left',
          formatter: (value, key, item) => {
            return item?.date
              ? this.$dateFormat(item.date, 'DD/MM/YYYY<br>HH:mm')
              : ''
          }
        },
        {
          key: 'staffUser',
          label: this.$t('fCustomer_statusLog_f_staff_user'),
          sortable: true,
          formatter: (value) => {
            return value ? `${value.email}&nbsp;(Id:&nbsp;${value.id})` : 'Hostess'
          }
        },
        {
          key: 'status',
          label: this.$t('fCustomer_statusLog_f_status'),
          tdClass: 'col-2 text-left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device'
    }),
    ...mapComputedStateFields('customerEdit', [
      'statusLog'
    ]),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    }
  },
  watch: {},
  async mounted () {
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style scoped>

</style>
